<template>
  <PerfectScrollbar>
    <div
      class="c-app flex-row align-items-center"
      :class="{ 'c-dark-theme': $store.state.layout.darkMode }"
    >
      <CContainer>
        <SecurlyMarketingBanner
          hide-banner
          heading="Looking into Pass for the first time?"
          link-text="Start Here"
          link="https://www.securly.com/pass"
        />
        <CRow class="justify-content-center">
          <CCol md="12" lg="6" xl="6">
            <CCardGroup>
              <CCard class="p-1">
                <CCardBody>
                  <CForm v-if="!redirectLoading">
                    <div class="d-flex justify-content-center mb-4">
                      <img
                        class="w-50"
                        src="@/assets/images/securlypass-color-logo.png"
                        alt="securlypass-logo"
                      />
                    </div>
                    <p class="text-muted mt-4">
                      If your school uses a third party service for
                      authentication (such as Google, Clever, Microsoft,
                      ClassLink, or GG4L) please log in by choosing the
                      appropriate button below.
                    </p>
                    <div class="pb-2" style="color: #2473e6">
                      If you have trouble logging in, please delete your browser
                      cache and cookies and try again. Here are instructions for
                      how to do this:
                      <a
                        href="https://www.refreshyourcache.com/en/home/"
                        style="color: #2473e6; text-decoration: underline"
                        target="_blank"
                        >Instructions</a
                      >
                    </div>
                    <CRow>
                      <CCol md="12" class="text-center mb-3 px-0 px-md-2">
                        <div class="d-flex justify-content-center">
                          <div class="ext-login-container">
                            <div
                              class="ext-login-btn"
                              @click="loginGoogle('web')"
                            >
                              <span class="flaticon-google"
                                ><span class="path1" /><span
                                  class="path2" /><span class="path3" /><span
                                  class="path4"
                              /></span>
                            </div>
                            <p>Google</p>
                          </div>
                          <div class="ext-login-container">
                            <div
                              class="ext-login-btn"
                              @click="loginClever('web')"
                            >
                              <span class="flaticon-clever" />
                            </div>
                            <p>Clever</p>
                          </div>
                          <div class="ext-login-container">
                            <div class="ext-login-btn" @click="loginMS('web')">
                              <span class="flaticon-microsoft"
                                ><span class="path1" /><span
                                  class="path2" /><span class="path3" /><span
                                  class="path4" /><span class="path5" /><span
                                  class="path6" /><span class="path7"
                              /></span>
                            </div>
                            <p>Microsoft</p>
                          </div>
                          <div class="ext-login-container">
                            <div
                              class="ext-login-btn"
                              @click="loginClassLink('web')"
                            >
                              <span class="flaticon-sso" />
                            </div>
                            <p>ClassLink</p>
                          </div>
                          <div class="ext-login-container">
                            <div
                              class="ext-login-btn"
                              @click="loginGG4L('web')"
                            >
                              <span class="flaticon-gg4l" />
                            </div>
                            <p>GG4L</p>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CInputGroup
                      :class="{
                        'is-invalid':
                          v$.user.$error && v$.user.email.required.$invalid
                      }"
                    >
                      <CInputGroupText class="input-group-prepend">
                        <CIcon icon="cil-user" />
                      </CInputGroupText>
                      <CFormInput
                        :class="{
                          'is-invalid':
                            v$.user.$error && v$.user.email.required.$invalid
                        }"
                        class="mb-0"
                        placeholder="Email"
                        autocomplete="email"
                        data-test-id="login-email"
                        :is-valid="checkIfValid('email')"
                        v-model="v$.user.email.$model"
                        @keyup="checkForEnter"
                      >
                      </CFormInput>
                    </CInputGroup>
                    <div
                      data-test-id="login-username-error"
                      v-if="v$.user.$error && v$.user.email.required.$invalid"
                      class="invalid-feedback"
                    >
                      This field is required
                    </div>
                    <CInputGroup
                      class="mt-3 mb-0"
                      :class="{
                        'is-invalid':
                          v$.user.$error && v$.user.password.required.$invalid
                      }"
                    >
                      <CInputGroupText class="input-group-prepend">
                        <CIcon icon="cil-lock-locked" />
                      </CInputGroupText>
                      <CFormInput
                        :class="{
                          'is-invalid':
                            v$.user.$error && v$.user.password.required.$invalid
                        }"
                        placeholder="Password"
                        type="password"
                        data-test-id="login-password"
                        autocomplete="current-password"
                        :is-valid="checkIfValid('password')"
                        v-model="v$.user.password.$model"
                        @keyup="checkForEnter"
                      >
                      </CFormInput>
                    </CInputGroup>
                    <div
                      data-test-id="login-password-error"
                      v-if="
                        v$.user.$error && v$.user.password.required.$invalid
                      "
                      class="invalid-feedback"
                    >
                      This field is required
                    </div>

                    <CAlert
                      data-test-id="login-error"
                      v-if="error"
                      class="mt-3"
                      color="danger"
                      :show="true"
                    >
                      {{
                        error == "Invalid user credentials."
                          ? "Invalid Credentials - please use “Forgot Password” link or contact your school Admin."
                          : error
                      }}
                    </CAlert>
                    <CAlert
                      v-if="unverifiedEmail"
                      class="mt-3 d-block"
                      color="danger"
                      :show="true"
                    >
                      This email has not been verified. Please check your email
                      to complete verification or
                      <span
                        class="cursor-pointer"
                        style="color: #2473e6; text-decoration: underline"
                        @click="navigateToVerifyEmail"
                        >click here</span
                      >
                      to enter your verification code.
                    </CAlert>
                    <div class="d-flex justify-content-end">
                      <CButton
                        data-test-id="login-forgot-password-btn"
                        color="link"
                      >
                        <router-link to="/forgotten-password">
                          Forgot password?
                        </router-link>
                      </CButton>
                    </div>
                    <CRow class="mt-3">
                      <CCol md="12" class="text-center">
                        <div @click="login()">
                          <CButton
                            class="me-2 btn bg-gradient-blue text-white"
                            data-test-id="login-submit"
                          >
                            Login
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                  </CForm>
                  <CCol md="12" class="text-center mb-3">
                    <CSpinner
                      v-if="isLoading && !goToApp && !goToVisitorApp"
                      color="primary"
                    />
                  </CCol>
                  <div
                    v-if="goToApp || goToVisitorApp"
                    class="d-flex flex-column flex-wrap align-items-center gap-4"
                  >
                    <div style="color: #2473e6">
                      If you are logging into the Securly Pass App please click
                      here
                    </div>
                    <a class="btn bg-gradient-blue text-white" :href="goToApp">
                      Go To PASS App
                    </a>
                    <div style="color: #2473e6">
                      If you are logging into the Securly Visitor App please
                      click here
                    </div>
                    <a
                      class="btn bg-gradient-blue text-white"
                      :href="goToVisitorApp"
                    >
                      Go To Visitor App
                    </a>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </PerfectScrollbar>
</template>

<script>
import SecurlyMarketingBanner from "@/v3components/SecurlyMarketingBanner.vue"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import cookieService from "@/helpers/cookies.js"
import tokenService from "@/helpers/tokenService.js"
import localforage from "localforage"
/* eslint-disable */
const randomstring = require("randomstring")
const CryptoJS = require("crypto-js")
import sha256 from "crypto-js/sha256"
const base64url = require("base64url")
/* eslint-disable */

export default {
  name: "Login",
  components: {
    SecurlyMarketingBanner
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data: () => ({
    redirectLoading: false,
    isLoading: false,
    user: {
      email: "",
      password: ""
    },
    error: null,
    goToApp: null,
    goToVisitorApp: null,
    goToWeb: null,
    unverifiedEmail: false
  }),
  validations() {
    return {
      user: {
        email: {
          required
        },
        password: {
          required
        }
      }
    }
  },
  computed: {
    isValid() {
      return !this.v$.user.$invalid
    }
  },
  mounted() {
    cookieService.removeAllCookies()
    localforage.clear()

    if (this.$route.name !== "login") {
      this.redirectLoginToSource(this.$route.name)
    }

    if (this.$route.query && this.$route.query.mobile_redirect) {
      this.isLoading = true
      this.redirectLoading = true
      this.handleMobileRedirect(this.$route.query.mobile_redirect)
    }

    if (this.$route.query && this.$route.query.code) {
      if (this.$route.params.source === "mobile") {
        this.redirectLoading = true
      }
      this.isLoading = true

      const state =
        this.$route.query.state ??
        this.getProviderIDByName(this.$route.params.type)
      const redirectUrl = this.getRedirectUri(
        this.$route.params.source,
        this.$route.params.type
      )

      const params = {
        ...this.$route.query,
        redirectUri: redirectUrl,
        codeVerifier: sessionStorage.getItem("code_verifier")
          ? sessionStorage.getItem("code_verifier")
          : null,
        state: state,
        isMobile: this.$route.params.source === "mobile",
        storedVerifier: state == "20" ? this.getCodeVerifier(false) : null,
        generatedVerifier: state == "20" ? this.getCodeVerifier(false) : null
      }
      this.$store
        .dispatch("authentication/externalLogin", params)
        .then((response) => {
          const data = response.data
          if (this.$route.params.source === "mobile") {
            this.afterLoginMobile(data.access_token)
          } else {
            cookieService.createTlaspheCookie()
            this.afterLoginWeb(data)
          }
          this.isLoading = false
        })
        .catch((err) => {
          if (this.$route.params.source === "mobile") {
            this.afterLoginMobile(null, err.message)
          } else {
            this.isLoading = false
            this.error = err.message
          }
        })
    }

    if (this.$route.query && this.$route.query.token) {
      cookieService.createTlaspheCookie()
      const token = this.$route.query.token
      this.$store.dispatch("authentication/saveToken", token)
      this.$store.dispatch("authentication/getUserDetails").then((user) => {
        if (user && user.role_id == 1) {
          window.location.href = "/passes/create"
        } else {
          window.location.href = "/dashboard"
        }
      })
      window.location.href = window.location.origin + "/"
    }
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.v$.user[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "")
    },
    checkForEnter(e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    login() {
      this.errors = null
      this.error = null
      this.unverifiedEmail = false
      if (this.isValid) {
        this.isLoading = true
        this.$store
          .dispatch("authentication/login", this.user)
          .then((response) => {
            cookieService.createTlaspheCookie()
            const data = response.data
            if (data.redirect) {
              window.location.replace(data.url)
            }
            this.afterLoginWeb(data)
          })
          .catch((err) => {
            this.isLoading = false
            if (err.message == "This user is not verified.") {
              this.unverifiedEmail = true
              return
            }
            this.error = err.message
          })
      } else {
        this.v$.$touch()
      }
    },
    afterLoginWeb(data) {
      if (data.user && data.user.redirect) {
        window.location.replace(data.user.redirect)
      } else {
        if (data.access_token) {
          console.log("at", data.access_token)
          tokenService.saveDevToken(data.access_token)
        }
        this.$store.dispatch("authentication/saveToken", data.ws.token)
        console.log("ws", data.ws.token)
        if (data.user && data.user.created_at) {
          this.$store.commit("authentication/SET_USER", data.user)
        }
        if (
          data.user.force_password_change_at == null &&
          data.user.is_manual == true
        ) {
          window.location.replace("/password/reset")
        } else {
          localStorage.setItem("showAnnouncements", true)
          if (data && data.user && data.user.role_id === 1) {
            window.location.replace("/passes/create")
          } else {
            window.location.replace("/dashboard")
          }
        }
      }
    },
    afterLoginMobile(token, error) {
      const query = token ? "?token=" + token : "?error=" + error
      this.goToApp = import.meta.env.VITE_MOBILE_URL + "login" + query
      this.goToVisitorApp =
        import.meta.env.VITE_VISITOR_MOBILE_URL + "login" + query
      this.goToWeb = document.location.origin + "/login" + query
    },
    goToDesktop() {
      this.$store.dispatch("authentication/saveToken", token)
      window.open(this.goToWeb, "_blank")
    },
    getPKCE(random = true) {
      let code_verifier = randomstring.generate(128)

      if (!random) {
        const constant_verifier = import.meta.env.VITE_VERIFIER
        const dateString = new Date()
          .toISOString()
          .slice(0, 10)
          .replace("-", "")
          .replace("-", "")
        code_verifier = btoa(
          constant_verifier.substring(0, 50) +
            dateString +
            constant_verifier.substring(50)
        ).substring(0, 128)
      }

      sessionStorage.setItem("code_verifier", code_verifier)
      //const base64Digest = sha256(code_verifier).toString().substring(0, 43)
      //const code_challenge = base64url.fromBase64(base64Digest)
      const code_challenge = sha256(code_verifier)
        .toString(CryptoJS.enc.Base64)
        .replaceAll("+", "-")
        .replaceAll("/", "_")
        .replace(/=+$/, "")

      return code_challenge
    },
    getCodeVerifier(random = true) {
      let code_verifier = randomstring.generate(128)

      if (!random) {
        const constant_verifier = import.meta.env.VITE_VERIFIER
        const dateString = new Date()
          .toISOString()
          .slice(0, 10)
          .replace("-", "")
          .replace("-", "")
        code_verifier = btoa(
          constant_verifier.substring(0, 50) +
            dateString +
            constant_verifier.substring(50)
        ).substring(0, 128)
      }
      sessionStorage.setItem("code_verifier", code_verifier)
      return code_verifier
    },
    getRedirectUri(source = "web", type = null) {
      type = type ? `/${type}` : ""
      return `${document.location.origin}/login/${source}${type}`
    },
    loginGG4L(source = "web") {
      const redirect_uri = this.getRedirectUri(source)
      const url = `https://sso.gg4l.com/oauth/auth?response_type=code&client_id=${import.meta.env.VITE_GG4L_CLIENT}&redirect_uri=${redirect_uri}/login&state=103`
      window.location.href = url
    },
    loginGoogle(source = "web") {
      const redirect_uri = this.getRedirectUri(source)
      const url = `https://accounts.google.com/o/oauth2/v2/auth/auth?client_id=${
        import.meta.env.VITE_GOOGLE_CLIENT_ID
      }&redirect_uri=${redirect_uri}&prompt=consent&access_type=offline&scope=email%20profile%20openid&code_challenge=${this.getPKCE()}&code_challenge_method=S256&flowName=GeneralOAuthFlow&state=10&response_type=code`
      window.location.href = url
    },
    loginMS(source = "web") {
      const redirect_uri = this.getRedirectUri(source)
      const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
        import.meta.env.VITE_MICROSOFT_CLIENT_ID
      }&redirect_uri=${redirect_uri}&response_type=code&response_mode=query&scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read%20openid%20profile%20email%20offline_access&code_challenge=${this.getPKCE(
        false
      )}&code_challenge_method=S256&state=20`
      window.location.href = url
    },
    loginClassLink(source = "web") {
      const redirect_uri = this.getRedirectUri(source, "classlink")
      const url = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${redirect_uri}&client_id=${import.meta.env.VITE_CLASSLINK_CLIENT_ID}&response_type=code&state=101`
      window.location.href = url
    },
    loginClever(source = "web") {
      const redirect_uri = this.getRedirectUri(source, "clever")
      //https://clever.com/in/protective-information-7564
      const url = `https://clever.com/oauth/authorize?client_id=${import.meta.env.VITE_CLEVER_CLIENT_ID}&redirect_uri=${redirect_uri}&response_type=code&state=102`
      window.location.href = url
    },
    getProviderIDByName(name) {
      const providers = {
        clever: 102,
        classlink: 101
      }
      return providers[name]
    },
    handleMobileRedirect(type) {
      if (type === "gg4l") {
        this.loginGG4L("mobile")
      } else if (type === "google") {
        this.loginGoogle("mobile")
      } else if (type === "microsoft") {
        this.loginMS("mobile")
      } else if (type === "classlink") {
        this.loginClassLink("mobile")
      } else if (type === "clever") {
        this.loginClever("mobile")
      }
      this.isLoading = false
    },
    redirectLoginToSource(source) {
      switch (source) {
        case "glogin":
          this.loginGoogle()
          break

        case "ologin":
          this.loginMS()
          break

        case "clogin":
          this.loginClever()
          break

        case "classlink":
          this.loginClassLink()
          break

        case "gg4l":
          this.loginGG4L()
          break

        default:
          break
      }
    },
    navigateToVerifyEmail() {
      this.$router.push("/sso/verifyEmail")
    }
  }
}
</script>
<style scoped lang="scss">
.ext-login-container {
  .ext-login-btn {
    background-color: #e1e1e1;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin: 5px 10px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      margin: 5px;
      width: 42px;
      height: 42px;
    }
  }
  p {
    font-size: 12px;
  }
}

.ps {
  max-height: 100vh;
}
</style>
