<template>
  <div class="tardy-reports-datatable-container">
    <Loader :is-processing="state.isLoading" />
    <ActionPanel
      @tab-clicked="setActiveTab"
      class="mb-3"
      show-sort
      show-export
      :export-loading="state.isLoading"
      :tabs="tabs"
      @on-sort="toggleFilter"
      @on-export="exportCSV"
    >
      <template #bottom-content>
        <CRow
          class="mt-3"
          v-if="
            state.isReportsFilterVisible &&
            state.activeTab &&
            state.activeTab.value == 'reports'
          "
        >
          <CCol class="w-full">
            <CRow>
              <div class="d-flex w-auto">
                <CustomSelect
                  v-if="gradeYears"
                  v-model="state.filterParams.by_school_year"
                  class="select-width-200 me-2"
                  :options="gradeYears"
                  placeholder="Select year"
                  :close-on-select="true"
                  :invalid-feedback="null"
                  @update:model-value="setCalendarGradeYears"
                ></CustomSelect>
                <CustomSchoolYearSelectDatePicker
                  class="tardy-report-datatable-container-date-picker"
                  v-model="state.selectedDateRange"
                  :range-options="state.optionalDateRanges"
                  :is-current-school-year-selected="currentSchoolYearSelected"
                  :selected-school-year-limit="
                    state.filterParams.by_school_year
                  "
                ></CustomSchoolYearSelectDatePicker>
              </div>
              <CustomCheck
                class="tardy-report-datatable-container-checkbox mt-2"
                :model-value="state.lts"
                :label="'LTS'"
                @change="changeLTS"
              ></CustomCheck>
              <CustomCheck
                class="tardy-report-datatable-container-checkbox mt-2"
                :model-value="state.ltc"
                :label="'LTC'"
                @change="changeLTC"
              ></CustomCheck>
              <CustomCheck
                class="tardy-report-datatable-container-checkbox mt-2 mw-fit"
                v-model="state.studentNumber.reports"
                label="Student Number"
              ></CustomCheck>
            </CRow>
          </CCol>
          <div class="text-end">
            <BaseButton class="" solid rounded @click="getReports(null, 1)"
              >Submit</BaseButton
            >
            <BaseButton class="ms-3" rounded @click="clearAll()"
              >Clear all</BaseButton
            >
          </div>
        </CRow>
        <CRow
          class="mt-3"
          v-show="
            state.isDetailedReportsFilterVisible &&
            state.activeTab &&
            state.activeTab.value == 'detailed'
          "
        >
          <CCol class="w-full">
            <CRow>
              <LazyLoadSelect
                class="tardy-report-datatable-container-select mb-2"
                placeholder="Select student"
                type="student"
                :selected="state.selectedStudent"
                :show-inactive="true"
                @changed="
                  (value) => {
                    state.selectedStudent = value
                  }
                "
              >
              </LazyLoadSelect>
              <div class="d-flex">
                <CustomSelect
                  v-if="gradeYears"
                  v-model="state.filterParams.by_school_year"
                  class="select-width-200 me-2"
                  :options="gradeYears"
                  placeholder="Select year"
                  :close-on-select="true"
                  :invalid-feedback="null"
                  @update:model-value="setCalendarGradeYears"
                ></CustomSelect>
                <CustomSchoolYearSelectDatePicker
                  class="tardy-report-datatable-container-date-picker"
                  v-model="state.selectedDateRange"
                  :range-options="state.optionalDateRanges"
                  :is-current-school-year-selected="currentSchoolYearSelected"
                  :selected-school-year-limit="
                    state.filterParams.by_school_year
                  "
                ></CustomSchoolYearSelectDatePicker>
              </div>
              <CustomCheck
                class="tardy-report-datatable-container-checkbox mt-2"
                :model-value="state.lts"
                :label="'LTS'"
                @change="changeLTS"
              ></CustomCheck>
              <CustomCheck
                class="tardy-report-datatable-container-checkbox mt-2"
                :model-value="state.ltc"
                :label="'LTC'"
                @change="changeLTC"
              ></CustomCheck>
              <CustomCheck
                class="tardy-report-datatable-container-checkbox mt-2 mw-fit"
                v-model="state.studentNumber.detailedReports"
                label="Student Number"
              ></CustomCheck>
            </CRow>
          </CCol>
          <div class="text-end">
            <BaseButton solid rounded @click="getDetailedReports(null, 1)"
              >Submit</BaseButton
            >
            <BaseButton class="ms-4" rounded @click="clearAll()"
              >Clear all</BaseButton
            >
          </div>
        </CRow>
      </template>
    </ActionPanel>

    <PerPage
      class="mb-3"
      v-if="state.pagination"
      :pagination="state.pagination"
      :showing-entries="true"
      @on-page-change="setPerPage"
    />

    <DataTable
      v-if="activeTable.items"
      class="text-center"
      :fields="activeTable.fields"
      :items="activeTable.items"
    >
      <template #student_first_name="{ item }">
        <td>{{ item.user.first_name }}</td>
      </template>
      <template #student_last_name="{ item }">
        <td>{{ item.user.last_name }}</td>
      </template>
      <template #student_number="{ item }">
        <td>{{ item.user.student_sis_id }}</td>
      </template>
      <template #teacher_name="{ item }">
        <td>
          {{
            item.teacher_name && item.tardy_badge === "LTC"
              ? item.teacher_name
              : "-"
          }}
        </td>
      </template>
      <template #date="{ item }">
        <td>{{ item.date }}</td>
      </template>
      <template #tardy_badge="{ item }">
        <td>
          <img
            v-if="item.tardy_badge === 'LTC'"
            width="25"
            height="30"
            src="@/assets/images/late-to-class.png"
          />
          <img
            v-if="item.tardy_badge === 'LTS'"
            width="25"
            height="30"
            src="@/assets/images/late-to-school.png"
          />
        </td>
      </template>
      <template #total="{ item }">
        <td>{{ item.infraction_step }}</td>
      </template>
    </DataTable>
    <Pagination
      v-if="
        state.pagination.pages &&
        state.pagination.pages > 1 &&
        !state.isLazyLoadingMode
      "
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
  </div>
</template>
<script>
import Loader from "@/v3components/shared/Loader/Loader.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import CustomSchoolYearSelectDatePicker from "@/v3components/shared/Form/CustomSchoolYearSelectDatePicker.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import moment from "moment-timezone"
import helpers from "../../helpers/index.js"
import { useStore } from "vuex"
import { onMounted, onUnmounted, computed, reactive, inject } from "vue"
import download from "@/helpers/downloadCSV.js"

export default {
  name: "TardyReportsTable",
  components: {
    Loader,
    ActionPanel,
    DataTable,
    Pagination,
    CustomCheck,
    CustomSelect,
    CustomSchoolYearSelectDatePicker,
    LazyLoadSelect,
    BaseButton,
    PerPage
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")
    const state = reactive({
      isReportsFilterVisible: true,
      isDetailedReportsFilterVisible: true,
      activeTab: null,
      lts: true,
      ltc: true,
      studentNumber: {
        reports: true,
        detailedReports: true
      },
      selectedStudent: null,
      selectedDateRange: null,
      isLazyLoadingMode: false,
      isLoading: false,
      isMounted: false,
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      optionalDateRanges: [
        {
          label: "Today",
          id: "",
          range: helpers.stringDateRange(moment(), moment())
        },
        {
          label: "Yesterday",
          id: "",
          range: helpers.stringDateRange(
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          )
        },
        {
          label: "Last 7 days",
          id: "",
          range: helpers.stringDateRange(moment().subtract(7, "days"), moment())
        },
        {
          label: "Last 14 days",
          id: "",
          range: helpers.stringDateRange(
            moment().subtract(14, "days"),
            moment()
          )
        },
        {
          label: "Last 30 days",
          id: "",
          range: helpers.stringDateRange(
            moment().subtract(30, "days") < moment(helpers.minSchoolYearDate())
              ? moment(helpers.minSchoolYearDate())
              : moment().subtract(30, "days"),
            moment()
          )
        },
        {
          label: "This month",
          id: "",
          range: helpers.stringDateRange(moment().startOf("month"), moment())
        },
        {
          label: "This year",
          id: "",
          range: helpers.stringDateRange(
            moment().isAfter(moment().startOf("year").add(6, "M"))
              ? moment().startOf("year").add(6, "M")
              : moment().startOf("year").add(6, "M").subtract(1, "Y"),
            moment()
          )
        },
        {
          label: "Custom Range",
          id: "custom",
          range: null
        }
      ],
      filterParams: {
        by_school_year: [],
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY")
      }
    })

    onMounted(() => {
      state.isMounted = true
      state.activeTab = tabs[0]
      state.filterParams.by_school_year = gradeYears.value
        ? gradeYears.value[gradeYears.value.length - 1]
        : state.filterParams.by_school_year
      initLazyLoading()
    })

    onUnmounted(() => {
      state.isMounted = false
    })

    // tableFields
    const reportTableFields = [
      {
        key: "student_first_name",
        label: "Student FN",
        sorter: false,
        filter: false
      },
      {
        key: "student_last_name",
        label: "Student LN",
        sorter: false,
        filter: false
      },
      {
        key: "student_number",
        label: "Student number",
        sorter: false,
        filter: false
      },
      { key: "date", label: "Date", sorter: false, filter: false },
      { key: "tardy_badge", label: "Tardy type", sorter: false, filter: false },

      {
        key: "total",
        label: "Total",
        sorter: false,
        filter: false
      }
    ]

    const detailedTableFields = [
      {
        key: "student_first_name",
        label: "Student FN",
        sorter: false,
        filter: false
      },
      {
        key: "student_last_name",
        label: "Student LN",
        sorter: false,
        filter: false
      },
      {
        key: "student_number",
        label: "Student number",
        sorter: false,
        filter: false
      },
      {
        key: "teacher_name",
        label: "Teacher name",
        sorter: false,
        filter: false
      },
      { key: "date", label: "Date & Time", sorter: false, filter: false },
      { key: "tardy_badge", label: "Tardy type", sorter: false, filter: false },

      {
        key: "total",
        label: "Total",
        sorter: false,
        filter: false
      }
    ]
    // reports
    const reports = computed(() => store.getters["reports/reports"])

    const minDate = computed(() => {
      if (state.filterParams.by_school_year?.value) {
        return new Date(state.filterParams.by_school_year.value[0], 6, 1)
      }
      return new Date()
    })

    const maxDate = computed(() => {
      if (state.filterParams.by_school_year?.value) {
        return new Date(state.filterParams.by_school_year.value[1], 5, 30)
      }
      return new Date()
    })

    const gradeYears = computed(() => {
      const gradeYears = []
      const currentYear = new Date().getFullYear()
      const today = moment().format("YYYY-MM-DD HH:mm:ss")
      const newSchoolYear = moment(today).isAfter(
        moment(`${currentYear}-06-30 23:59:59`)
      )
      if (moment(today).year() == currentYear && newSchoolYear) {
        for (let i = 0; i <= currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      } else {
        for (let i = 0; i < currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      }
      return gradeYears
    })

    const currentSchoolYearSelected = computed(() => {
      const currentYearIndex = gradeYears.value.length - 1
      return (
        state.filterParams.by_school_year?.label ==
        gradeYears.value[currentYearIndex].label
      )
    })

    const setCalendarGradeYears = () => {
      state.selectedDateRange = `${helpers.currTzDate(minDate.value)} - ${helpers.currTzDate(maxDate.value)}`
    }

    const getReportsParams = () => {
      return {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value,
        between_dates: state.selectedDateRange
          ? state.selectedDateRange
              .replace(/\//g, ".")
              .split(" - ")
              .map((date) => moment(date, "MM/DD/YYYY").format("MM/DD/YYYY"))
          : [moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY")],
        tardy_badges:
          state.lts && state.ltc
            ? ["LTC", "LTS"]
            : state.lts
              ? ["LTS"]
              : state.ltc
                ? ["LTC"]
                : !state.lts && !state.ltc
                  ? [""]
                  : null,
        student_sis_id: state.studentNumber.reports
      }
    }

    const getReports = (isLazyLoadingMode, page) => {
      state.pagination.activePage = page ? page : 1
      const params = Object.assign(getReportsParams())
      state.isLoading = true
      store.dispatch("reports/getReports", params).then((response) => {
        state.isLoading = false
        const data = response.data
        if (isLazyLoadingMode) {
          store.commit("reports/PUSH_REPORTS", data.data)
        } else {
          store.commit("reports/SET_REPORTS", data.data)
        }
        if (data.meta) {
          setPagination({
            total: data.meta.total,
            from: data.meta.from,
            to: data.meta.to,
            pages: Math.ceil(data.meta.total / data.meta.per_page),
            activePage: Number(state.pagination.activePage)
          })
        }
      })
    }
    // detailed
    const detailedReports = computed(
      () => store.getters["reports/detailedReports"]
    )

    const getDetailedReportsParams = () => {
      return {
        per_page: state.pagination.per_page.value,
        page: state.pagination.activePage,
        between_dates: state.selectedDateRange
          ? state.selectedDateRange
              .replace(/\//g, ".")
              .split(" - ")
              .map((date) => moment(date, "MM/DD/YYYY").format("MM/DD/YYYY"))
          : [moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY")],
        tardy_badges:
          state.lts && state.ltc
            ? ["LTC", "LTS"]
            : state.lts
              ? ["LTS"]
              : state.ltc
                ? ["LTC"]
                : !state.lts && !state.ltc
                  ? [""]
                  : null,
        student_id: state.selectedStudent
          ? state.selectedStudent.value.id
          : null,
        student_sis_id: state.studentNumber.detailedReports
      }
    }

    const getDetailedReports = (isLazyLoadingMode, page) => {
      state.pagination.activePage = page ? page : 1
      const params = Object.assign(getDetailedReportsParams())
      state.isLoading = true
      store.dispatch("reports/getDetailedReports", params).then((response) => {
        state.isLoading = false
        const data = response.data
        if (isLazyLoadingMode) {
          store.commit("reports/PUSH_DETAILED_REPORTS", data.data)
        } else {
          store.commit("reports/SET_DETAILED_REPORTS", data.data)
        }
        if (data.meta) {
          setPagination({
            total: data.meta.total,
            from: data.meta.from,
            to: data.meta.to,
            pages: Math.ceil(data.meta.total / data.meta.per_page),
            activePage: Number(state.pagination.activePage)
          })
        }
      })
    }

    const reportsCollection = computed(() =>
      reports.value.map((report) => {
        const date = moment(report.date).format("MM/DD/YYYY")
        return {
          ...report,
          date
        }
      })
    )

    const detailedReportsCollection = computed(() =>
      detailedReports.value.map((detailedReport) => {
        const date = moment(detailedReport.date).format("MM/DD/YYYY h:mm a")
        return {
          ...detailedReport,
          date
        }
      })
    )

    const isReportTab = computed(
      () => state.activeTab && state.activeTab.value === "reports"
    )
    const filterTableFields = (fields = [], condition = false) => {
      if (!fields.length) return
      return fields.filter((field) =>
        !condition ? field.key !== "student_number" : field
      )
    }
    const activeTable = computed(() => {
      return !isReportTab.value
        ? {
            items: detailedReportsCollection.value,
            fields: filterTableFields(
              detailedTableFields,
              state.studentNumber.detailedReports
            )
          }
        : {
            items: reportsCollection.value,
            fields: filterTableFields(
              reportTableFields,
              state.studentNumber.reports
            )
          }
    })

    const tabs = [
      {
        label: "LTS & LTC",
        value: "reports",
        action: getReports
      },
      {
        label: "Detailed",
        value: "detailed"
      }
    ]

    const resetFilterValues = () => {
      state.isLazyLoadingMode = false
      state.isReportsFilterVisible = true
      state.isDetailedReportsFilterVisible = true
      state.lts = true
      state.ltc = true
      state.selectedStudent = null
      state.filterParams.by_school_year = gradeYears.value
        ? gradeYears.value[gradeYears.value.length - 1]
        : state.filterParams.by_school_year
      state.selectedDateRange = state.optionalDateRanges[0].range
      setPagination({
        activePage: 1,
        total: 0,
        from: 0,
        to: 0,
        pages: 0
      })
    }
    const setActiveTab = (tab) => {
      state.activeTab = tab
      resetFilterValues()
      helpers.scrollToTop()
      if (tab.value === "reports") {
        try {
          store.commit("reports/SET_DETAILED_REPORTS", [])
          tab.action()
        } catch (error) {
          throw new Error(error)
        }
      }
    }
    const setPagination = (data) => {
      state.pagination = Object.assign(state.pagination, data)
    }

    const setActivePage = (page) => {
      isReportTab.value
        ? getReports(null, page)
        : getDetailedReports(null, page)
      helpers.scrollToTop()
    }

    const toggleFilter = () => {
      isReportTab.value
        ? (state.isReportsFilterVisible = !state.isReportsFilterVisible)
        : (state.isDetailedReportsFilterVisible =
            !state.isDetailedReportsFilterVisible)
    }

    const exportCSV = () => {
      isReportTab.value ? exportReports() : exportDetailedReports()
    }

    const exportReports = () => {
      const params = Object.assign(getReportsParams(), {
        page: state.pagination.activePage,
        per_page: [25, 50, 100].includes(state.pagination.per_page.value)
          ? state.pagination.per_page.value
          : "all"
      })

      state.isLoading = true

      store
        .dispatch("reports/getReportsCSV", params)
        .then((response) => {
          if (response && state.isMounted) {
            state.isLoading = false
            download.CSVExport(response.data, "tardy_reports")
          }
        })
        .catch((error) => {
          state.isLoading = false
          if (
            error?.response?.data?.error.status_code == 504 &&
            state.isMounted
          ) {
            modal.open(InfoBox, {
              size: "lg",
              title: "Something went wrong!",
              props: {
                message:
                  "Search criteria is too large. Please reduce the date range and try again."
              }
            })
          }
        })
    }

    const exportDetailedReports = () => {
      const params = Object.assign(getDetailedReportsParams(), {
        page: state.pagination.activePage,
        per_page: [25, 50, 100].includes(state.pagination.per_page.value)
          ? state.pagination.per_page.value
          : "all"
      })

      state.isLoading = true

      store
        .dispatch("reports/getDetailedReportsCSV", params)
        .then((response) => {
          if (response && state.isMounted) {
            state.isLoading = false
            download.CSVExport(response.data, "tardy_reports")
          }
        })
        .catch((error) => {
          state.isLoading = false
          if (
            error?.response?.data?.error.status_code == 504 &&
            state.isMounted
          ) {
            modal.open(InfoBox, {
              size: "lg",
              title: "Something went wrong!",
              props: {
                message:
                  "Search criteria is too large. Please reduce the date range and try again."
              }
            })
          }
        })
    }

    const changeLTS = () => {
      state.lts = !state.lts
    }

    const changeLTC = () => {
      state.ltc = !state.ltc
    }

    const clearAll = () => {
      resetFilterValues()
      isReportTab.value ? getReports() : getDetailedReports()
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        if (isReportTab.value && reports.value && reports.value.length) {
          state.pagination.activePage = 1
          getReports()
        }
        if (
          !isReportTab.value &&
          detailedReports.value &&
          detailedReports.value.length
        ) {
          state.pagination.activePage = 1
          getDetailedReports()
        }
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (isReportTab.value) {
            if (
              state.isLazyLoadingMode &&
              reports.value &&
              reports.value.length &&
              state.pagination.total > reports.value.length
            ) {
              if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
                state.pagination.activePage = state.pagination.activePage + 1
                getReports(true, state.pagination.activePage)
              }
            }
          } else {
            if (
              state.isLazyLoadingMode &&
              detailedReports.value &&
              detailedReports.value.length &&
              state.pagination.total > detailedReports.value.length
            ) {
              if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
                state.pagination.activePage = state.pagination.activePage + 1
                getDetailedReports(true, state.pagination.activePage)
              }
            }
          }
        }
      }
    }

    return {
      state,
      tabs,
      setActiveTab,
      reportTableFields,
      detailedTableFields,
      reports,
      minDate,
      maxDate,
      gradeYears,
      setCalendarGradeYears,
      currentSchoolYearSelected,
      setActivePage,
      activeTable,
      toggleFilter,
      exportCSV,
      changeLTS,
      changeLTC,
      getReports,
      getDetailedReports,
      clearAll,
      setPerPage
    }
  }
}
</script>

<style lang="scss" scoped>
.select-width-200 {
  width: 200px;
}
</style>
