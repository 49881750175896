<template>
  <div
    data-test-id="dashboard-future-apt-tab"
    class="dashboard-tab-list-item"
    :class="[tabClass, { active: isActive }]"
    @click="$emit('onTabClick', value)"
  >
    <span v-if="icon" class="tab-icon" :class="icon"></span>
    <span v-html="label"></span>
    <span
      v-if="count && stats && stats[count]"
      class="counter-badge"
      :style="{
        fontSize: stats[count] > 99 ? '10px' : '12px'
      }"
    >
      {{ stats[count] }}
    </span>
  </div>
</template>

<script>
export default {
  name: "DashboardTabListItem",
  props: {
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    tabClass: {
      type: String,
      default: ""
    },
    count: {
      type: String,
      default: ""
    },
    isActive: {
      type: Boolean,
      default: false
    },
    stats: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["onTabClick"]
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/v3scss/_variables.scss";

.dashboard-tab-list-item {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  gap: 8px;
  width: 100px;
  background-color: white;
  border-right: 1px solid $gray-light;
  color: $black;
  text-align: left;
  font-size: 12px;

  &.active {
    background-color: $cyan-light;
    border-bottom: 3px solid $accent-blue-light;
    border-right: none;
  }

  .counter-badge {
    position: absolute;
    top: -8px;
    right: 16px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    color: $black;
    background-color: $white;
    border: 1px solid $gray-light;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &.red {
    background-color: rgba($red, 0.1);
    &.active {
      background-color: rgba($red, 0.4);
      border-bottom: 3px solid $red;
      border-right: none;
    }
    .counter-badge {
      color: $white;
      background-color: $red;
    }
  }

  &.green {
    background-color: rgba($orange-light, 0.1);
    &.active {
      background-color: rgba($orange-light, 0.4);
      border-bottom: 3px solid $orange-light;
      border-right: none;
    }
    .counter-badge {
      color: $white;
      background-color: $orange-light;
    }
  }

  &.purple {
    background-color: rgba($purple, 0.05);
    &.active {
      background-color: rgba($purple, 0.4);
      border-bottom: 3px solid $purple;
      border-right: none;
    }
    .counter-badge {
      color: $white;
      background-color: $purple;
    }
  }
}
</style>
