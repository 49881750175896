<template>
  <CDropdown
    v-if="activeSchool"
    variant="nav-item"
    class="v3-sidebar-school-dropdown"
    placement="bottom-end"
    :class="{ 'no-dropdown': noSchoolOptions }"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center my-1"
    >
      <CSpinner color="primary" size="sm" />
    </div>
    <CDropdownToggle
      data-test-id="sidebar-school-dropdown"
      component="button"
      @click="(e) => e.preventDefault()"
    >
      <div
        class="d-flex align-items-center"
        :class="{ 'cursor-default': noSchoolOptions }"
      >
        <div class="v3-avatar">
          <img
            onerror="javascript: this.src='/img/avatars/school-avatar.png'"
            :src="activeSchool.logo"
          />
        </div>
        <div class="px-2 flex-1">
          <p class="m-0 font-size-12">School</p>
          <b
            class="flex-grow-1 font-size-14 text-wrap text-break"
            data-test-id="sidebar-school-name"
            >{{ activeSchool.name }}</b
          >
        </div>
      </div>
    </CDropdownToggle>
    <CDropdownMenu v-if="!noSchoolOptions">
      <CDropdownItem
        v-for="(school, index) in schoolOptions"
        :key="index"
        class="px-2 cursor-pointer"
        @click="setActiveSchool(school)"
      >
        <div
          class="text-start school-name flex-grow-1 text-wrap"
          data-test-id="sidebar-school-list"
        >
          {{ school.name }}
        </div>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      isLoading: false,
      schoolSelectHeight: null,
      isSelectScrolable: false
    }
  },
  computed: {
    ...mapState({
      schools: (state) => state.schools.schools,
      activeSchool: (state) => state.schools.activeSchool
    }),
    role() {
      return this.$store.getters["authentication/getUserRole"]
    },
    schoolOptions() {
      return this.schools && this.schools.length
        ? this.schools.filter((el) => el.id !== this.activeSchool.id)
        : []
    },
    noSchoolOptions() {
      return !this.schoolOptions.length
    }
  },
  mounted() {
    this.initSchoolsSelectSize()
  },
  methods: {
    async setActiveSchool(school) {
      if (school && school.name !== this.activeSchool.name) {
        try {
          this.isLoading = true
          await this.$store.dispatch("schools/changeActiveSchool", school)
          await this.$store.dispatch("authentication/getUserDetails", {
            clearCacheEntry: true
          })

          await this.$store.dispatch("schools/getSchools", {
            clearCacheEntry: true
          })

          if (this.role === "student") {
            await this.$store.dispatch("schools/getActiveModules", {
              clearCacheEntry: true
            })
            await this.$store.dispatch("favorites/getFavorites", {
              clearCacheEntry: true
            })
          } else {
            await this.$store.dispatch("authentication/getUserSettings", {
              clearCacheEntry: true
            })
          }

          if (window?.vueRouter) {
            await window.vueRouter.push("/dashboard")
          }

          window?.location?.reload()
        } catch (error) {
          const message =
            error?.response?.data?.message ||
            error?.message ||
            "Something went wrong while changing school"
          localStorage.setItem(`SchoolChangeEvent - ${school.name}`, message)
        } finally {
          this.isLoading = false
        }
      }
    },
    initSchoolsSelectSize() {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (this.schoolOptions && body) {
        this.isSelectScrolable =
          this.schoolOptions.length * 77 + 100 > body.offsetHeight
      }
      this.schoolSelectHeight =
        body && this.schoolOptions && this.isSelectScrolable
          ? body.offsetHeight + "px"
          : "auto"
    }
  }
}
</script>
