<template>
  <div class="detentions-datatable-container">
    <Loader :is-processing="state.isLoading" />
    <ActionPanel
      @tab-clicked="setActiveTab"
      class="mb-3"
      :tabs="tabs"
      :show-export="true"
      @on-export="exportFile"
    >
      <template #bottom-content>
        <div class="d-flex mt-3 flex-wrap">
          <CustomSelect
            v-if="gradeYears"
            v-model="state.form.schoolYear"
            class="min-w-200 me-2"
            :options="gradeYears"
            placeholder="Select year"
            :close-on-select="true"
            :invalid-feedback="null"
            @update:model-value="setCalendarGradeYears"
          ></CustomSelect>
          <CustomSchoolYearSelectDatePicker
            class=""
            v-model="state.form.selectedDateRange"
            :range-options="state.optionalDateRanges"
            :is-current-school-year-selected="currentSchoolYearSelected"
            :selected-school-year-limit="state.form.schoolYear"
          ></CustomSchoolYearSelectDatePicker>
        </div>
        <div class="text-end">
          <BaseButton solid rounded @click="getData">Submit</BaseButton>
        </div>
      </template>
    </ActionPanel>
    <DataTable :fields="activeTable.fields" :items="activeTable.items">
      <template #image="{ item }">
        <td>
          <img
            class="rounded"
            width="35"
            height="35"
            :src="
              item.user.avatar
                ? item.user.avatar
                : '/img/avatars/user-avatar.jpeg'
            "
          />
        </td>
      </template>
      <template #first_name="{ item }">
        <td>{{ item.user.first_name }}</td>
      </template>
      <template #last_name="{ item }">
        <td>{{ item.user.last_name }}</td>
      </template>
      <template #grade_year="{ item }">
        <td>{{ item.user.grade_year }}</td>
      </template>
      <template #step="{ item }">
        <td>{{ item.step ? item.step : "" }}</td>
      </template>
      <template #status="{ item }">
        <td><DetentionStatusPill :status="item.status" /></td>
      </template>
    </DataTable>
    <Pagination
      v-if="state && state.pagination && state.pagination.pages > 1"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    />
  </div>
</template>

<script>
import Loader from "@/v3components/shared/Loader/Loader.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import { useStore } from "vuex"
import moment from "moment-timezone"
import { onMounted, computed, reactive } from "vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import CustomSchoolYearSelectDatePicker from "@/v3components/shared/Form/CustomSchoolYearSelectDatePicker.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import DetentionStatusPill from "../shared/Tardy/DetentionStatusPill.vue"
import helpers from "../../helpers/index.js"
import download from "@/helpers/downloadCSV.js"

export default {
  name: "DetentionsAndAppointmentsTable",
  components: {
    Loader,
    ActionPanel,
    BaseButton,
    DataTable,
    CustomSelect,
    CustomSchoolYearSelectDatePicker,
    Pagination,
    DetentionStatusPill
  },
  setup() {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      activeTab: null,
      form: {
        dateFilter: moment()._d,
        schoolYear: [],
        selectedDateRange: null
      },
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      optionalDateRanges: [
        {
          label: "Today",
          id: "",
          range: helpers.stringDateRange(moment(), moment())
        },
        {
          label: "Yesterday",
          id: "",
          range: helpers.stringDateRange(
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          )
        },
        {
          label: "Last 7 days",
          id: "",
          range: helpers.stringDateRange(moment().subtract(7, "days"), moment())
        },
        {
          label: "Last 14 days",
          id: "",
          range: helpers.stringDateRange(
            moment().subtract(14, "days"),
            moment()
          )
        },
        {
          label: "Last 30 days",
          id: "",
          range: helpers.stringDateRange(
            moment().subtract(30, "days") < moment(helpers.minSchoolYearDate())
              ? moment(helpers.minSchoolYearDate())
              : moment().subtract(30, "days"),
            moment()
          )
        },
        {
          label: "This month",
          id: "",
          range: helpers.stringDateRange(moment().startOf("month"), moment())
        },
        {
          label: "This year",
          id: "",
          range: helpers.stringDateRange(
            moment().isAfter(moment().startOf("year").add(6, "M"))
              ? moment().startOf("year").add(6, "M")
              : moment().startOf("year").add(6, "M").subtract(1, "Y"),
            moment()
          )
        },
        {
          label: "Custom Range",
          id: "custom",
          range: null
        }
      ]
    })

    onMounted(() => {
      state.activeTab = tabs[0]
      state.form.schoolYear = gradeYears.value
        ? gradeYears.value[gradeYears.value.length - 1]
        : state.form.schoolYear
    })

    const activeTable = computed(() => {
      return isDetentionMode.value
        ? {
            items: detentions.value,
            fields: detentionTableFields
          }
        : {
            items: appointments.value,
            fields: appointmentTableFileds
          }
    })

    const detentions = computed(
      () => store.getters["detentionsAppointments/detentions"]
    )
    const appointments = computed(
      () => store.getters["detentionsAppointments/appointments"]
    )
    const isDetentionMode = computed(
      () => state.activeTab && state.activeTab.value === "DETENTION"
    )

    const minSchoolYearDate = computed(() => {
      return helpers.minSchoolYearDate()
    })

    const maxSchoolYearDate = computed(() => {
      return helpers.maxSchoolYearDate()
    })

    const minDate = computed(() => {
      if (state.form.schoolYear?.value) {
        return new Date(state.form.schoolYear.value[0], 6, 1)
      }
      return new Date()
    })

    const maxDate = computed(() => {
      if (state.form.schoolYear?.value) {
        return new Date(state.form.schoolYear.value[1], 5, 30)
      }
      return new Date()
    })

    const gradeYears = computed(() => {
      const gradeYears = []
      const currentYear = new Date().getFullYear()
      const today = moment().format("YYYY-MM-DD HH:mm:ss")
      const newSchoolYear = moment(today).isAfter(
        moment(`${currentYear}-06-30 23:59:59`)
      )
      if (moment(today).year() == currentYear && newSchoolYear) {
        for (let i = 0; i <= currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      } else {
        for (let i = 0; i < currentYear - 2021; i++) {
          gradeYears.push({
            value: [2021 + i, 2022 + i],
            label: `${2021 + i}-${22 + i}`
          })
        }
      }
      return gradeYears
    })

    const currentSchoolYearSelected = computed(() => {
      const currentYearIndex = gradeYears.value.length - 1
      return (
        state.form.schoolYear?.label == gradeYears.value[currentYearIndex].label
      )
    })

    const setCalendarGradeYears = () => {
      state.form.selectedDateRange = `${helpers.currTzDate(minDate.value)} - ${helpers.currTzDate(maxDate.value)}`
    }

    const getData = () => {
      const data = {
        between_dates: state.form.selectedDateRange
          ? state.form.selectedDateRange
              .replace(/\//g, ".")
              .split(" - ")
              .map((date) => moment(date, "MM/DD/YYYY").format("MM/DD/YYYY"))
          : [moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY")],
        type: state.activeTab.value,
        per_page: state.pagination.per_page.value,
        page: state.pagination.activePage
      }
      state.isLoading = true
      store
        .dispatch("detentionsAppointments/getDetentions", data)
        .then((response) => {
          state.isLoading = false
          if (response && response.data && response.data.meta) {
            const data = response.data.meta

            setPagination({
              total: data.total,
              from: data.from,
              to: data.to,
              pages: Math.ceil(data.total / data.per_page),
              activePage: Number(state.pagination.activePage)
            })
          }
        })
    }

    const setPagination = (data) => {
      state.pagination = Object.assign(state.pagination, data)
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getData()
    }

    const detentionTableFields = [
      { key: "image", label: "Image", sorter: false, filter: false },
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      { key: "grade_year", label: "Grad year", sorter: false, filter: false },
      {
        key: "step",
        label: "Infraction step",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Detention type",
        sorter: false,
        filter: false
      },
      {
        key: "status",
        label: "Status",
        sorter: false,
        filter: false
      }
    ]

    const appointmentTableFileds = [
      { key: "image", label: "Image", sorter: false, filter: false },
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      { key: "grade_year", label: "Grad year", sorter: false, filter: false },
      {
        key: "step",
        label: "Infraction step",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Appointment type",
        sorter: false,
        filter: false
      }
    ]
    //table
    const tabs = [
      {
        label: "Detentions",
        value: "DETENTION",
        action: getData
      },
      {
        label: "Appointments",
        value: "APT",
        action: getData
      }
    ]
    const setActiveTab = (tab) => {
      state.activeTab = tab
      resetFilterValues()
      try {
        tab.action()
      } catch (error) {
        throw new Error(error)
      }
    }
    const resetFilterValues = () => {
      state.form.schoolYear = gradeYears.value
        ? gradeYears.value[gradeYears.value.length - 1]
        : state.form.schoolYear
      state.form.selectedDateRange = state.optionalDateRanges[0].range
    }
    const exportFile = () => {
      const params = {
        between_dates: state.form.selectedDateRange
          ? state.form.selectedDateRange
              .replace(/\//g, ".")
              .split(" - ")
              .map((date) => moment(date, "MM/DD/YYYY").format("MM/DD/YYYY"))
          : [moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY")],
        type: state.activeTab.value,
        page: state.pagination.activePage,
        per_page: [25, 50, 100].includes(state.pagination.per_page.value)
          ? state.pagination.per_page.value
          : "all"
      }
      store
        .dispatch("detentionsAppointments/exportFile", params)
        .then((response) => {
          if (response) {
            download.CSVExport(
              response.data,
              state.activeTab.value === "DETENTION"
                ? "tardy_detentions.csv"
                : "tardy_appointments.csv"
            )
          }
        })
    }

    return {
      tabs,
      setActiveTab,
      minSchoolYearDate,
      maxSchoolYearDate,
      minDate,
      maxDate,
      gradeYears,
      currentSchoolYearSelected,
      setCalendarGradeYears,
      activeTable,
      isDetentionMode,
      exportFile,
      state,
      getData,
      setActivePage
    }
  }
}
</script>

<style>
.min-w-200 {
  min-width: 200px;
}
</style>
