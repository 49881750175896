<template>
  <div
    v-if="isPassNotEnded"
    :class="{ 'pass-status-loading': isAnyButtonLoading }"
  >
    <div class="table-actions">
      <div v-if="showApproveCancelSection" class="mt-2">
        <button
          class="btn btn-comfirm mb-1"
          :disabled="state.isApproving"
          @click="approvePass"
        >
          {{ state.isApproving ? "Approving..." : "Approve" }}
        </button>
        <button
          class="btn btn-cancel"
          :disabled="state.isCanceling"
          @click="cancelPass"
        >
          {{ state.isCanceling ? "Canceling..." : "Cancel" }}
        </button>
      </div>

      <div v-if="showArrivedInOutSection" class="mt-2">
        <div
          v-if="showArrivedInOutButtons"
          class="d-flex align-items-center mb-1"
        >
          <button
            class="btn btn-comfirm me-1"
            :disabled="state.isArriving"
            @click="markAsArrived"
          >
            {{ state.isArriving ? "Marking..." : "Arrived" }}
          </button>
          <button
            class="btn btn-comfirm in-out"
            :disabled="state.isInOut"
            @click="markAsInOut"
          >
            {{ state.isInOut ? "Processing..." : "In/Out" }}
          </button>
        </div>
        <button
          class="btn btn-cancel"
          :disabled="state.isEnding"
          @click="endPass"
        >
          {{ state.isEnding ? "Ending..." : "End/Keep" }}
        </button>
      </div>

      <div v-if="showReturnEndSection" class="mt-2">
        <button
          class="btn btn-comfirm no-icon me-1 mb-1"
          :disabled="state.isReturning"
          @click="markAsReturning"
        >
          <span class="flaticon-back-arrow me-1 icon-15"></span>
          {{ state.isReturning ? "Processing..." : "Returning" }}
        </button>
        <button
          class="btn btn-cancel"
          :disabled="state.isEnding"
          @click="endChildPass"
        >
          {{ state.isEnding ? "Ending..." : "End" }}
        </button>
      </div>

      <div v-if="showEndSection" class="mt-2">
        <button
          class="btn btn-cancel"
          :disabled="state.isEnding"
          @click="endChildPass"
        >
          {{ state.isEnding ? "Ending..." : "End" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification"
import { reactive, computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "PassActionButtons",
  props: {
    pass: {
      type: Object,
      required: true
    },
    actionsStatus: {
      type: Number,
      default: 1
    }
  },
  emits: ["passEnded", "cancelPass"],
  setup(props, { emit }) {
    const toast = useToast()
    const store = useStore()

    const state = reactive({
      isApproving: false,
      isCanceling: false,
      isArriving: false,
      isInOut: false,
      isEnding: false,
      isReturning: false
    })

    const isAnyButtonLoading = computed(() =>
      Object.values(state).some((loading) => loading)
    )

    const isPassNotEnded = computed(() => !props?.pass?.badge_flags?.ended)

    const showApproveCancelSection = computed(
      () =>
        Number(props.actionsStatus) === 1 &&
        !props?.pass?.approved_at &&
        props?.pass?.pass_status
    )

    const showArrivedInOutSection = computed(
      () =>
        props.actionsStatus === 2 &&
        props?.pass?.approved_at &&
        props?.pass?.pass_status
    )

    const showArrivedInOutButtons = computed(
      () =>
        props?.pass?.to_type === "App\\Models\\User" ||
        (props?.pass?.to_type === "App\\Models\\Room" &&
          props?.pass?.to?.trip_type === "Layover")
    )

    const showReturnEndSection = computed(
      () =>
        props.actionsStatus === 3 &&
        !props?.pass?.child?.approved_at &&
        props?.pass?.child?.pass_status
    )

    const showEndSection = computed(
      () =>
        props.actionsStatus === 4 &&
        props?.pass?.child?.approved_at &&
        props?.pass?.child?.pass_status
    )

    const approvePass = async () => {
      state.isApproving = true
      try {
        await store.dispatch("passes/setPassStatus", {
          id: props?.pass?.id,
          data: { action: 1 }
        })
        toast.success("Pass approved successfully")
      } catch (err) {
        handleError(err, "approving the pass")
      } finally {
        state.isApproving = false
      }
    }

    const cancelPass = async () => {
      state.isCanceling = true
      try {
        await store.dispatch("passes/cancelPass", props?.pass?.id)
        emit("cancelPass")
        toast.success("Pass canceled successfully")
      } catch (err) {
        handleError(err, "canceling the pass")
      } finally {
        state.isCanceling = false
      }
    }

    const markAsArrived = async () => {
      state.isArriving = true
      try {
        await updatePassStatus(3, null)
      } catch (err) {
        handleError(err, "marking as arrived")
      } finally {
        state.isArriving = false
      }
    }

    const markAsInOut = async () => {
      state.isInOut = true
      try {
        await updatePassStatus(4, "inout")
      } catch (err) {
        handleError(err, "processing in/out")
      } finally {
        state.isInOut = false
      }
    }

    const endPass = async () => {
      state.isEnding = true
      try {
        await updatePassStatus(2, null)
        emit("passEnded")
      } catch (err) {
        handleError(err, "ending the pass")
      } finally {
        state.isEnding = false
      }
    }

    const markAsReturning = async () => {
      state.isReturning = true
      try {
        await updatePassStatus(4, "return", true)
      } catch (err) {
        handleError(err, "marking as returning")
      } finally {
        state.isReturning = false
      }
    }

    const endChildPass = async () => {
      state.isEnding = true
      try {
        await updatePassStatus(2, null, true)
        emit("passEnded")
      } catch (err) {
        handleError(err, "ending the pass")
      } finally {
        state.isEnding = false
      }
    }

    const updatePassStatus = async (status, return_type, isChild = false) => {
      const data = {
        id: isChild ? props?.pass?.child?.id : props?.pass?.id,
        data: { action: status }
      }
      if (return_type) data.data.return_type = return_type
      if (isChild) data.data.child = true
      await store.dispatch("passes/setPassStatus", data)
      toast.success("Pass updated successfully")
    }

    const handleError = (err, action) => {
      const defaultMessage = `Shucks! Something went wrong while ${action}`
      let errMsg = err?.response?.data?.message || err?.message || ""
      if (errMsg) {
        const errorMap = {
          "Invalid pass action!": "Pass action failed. Please refresh the page."
        }
        errMsg = errorMap[errMsg] || errMsg
      }
      toast.error(errMsg ? `${defaultMessage}: ${errMsg}` : defaultMessage)
    }

    return {
      state,
      isAnyButtonLoading,
      isPassNotEnded,
      showApproveCancelSection,
      showArrivedInOutSection,
      showArrivedInOutButtons,
      showReturnEndSection,
      showEndSection,
      approvePass,
      cancelPass,
      markAsArrived,
      markAsInOut,
      endPass,
      markAsReturning,
      endChildPass
    }
  }
}
</script>

<style lang="scss" scoped>
.pass-status-loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>
