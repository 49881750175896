<template>
  <CContainer class="user-pass-history datatable-container p-0 pt-4">
    <Loader :is-processing="state.isLoading" classes="position-absolute" />
    <CRow class="justify-content-between">
      <CCol md="12">
        <div class="text-center mb-3">
          <div class="passes-total-time">
            Total time today: <b class="ms-4">{{ totalPassesTime }}</b>
          </div>
        </div>
        <div class="user-passes-history">
          <DataTable
            v-if="tablePassCollection"
            :items="tablePassCollection"
            :fields="state.dataTableFields"
            add-table-classes="student-history-table"
            server-side="true"
            :sorter="{ external: false, resetable: true }"
            :sorter-value="state.sorterValue"
            :loading="state.isLoading"
            :no-items-view="{
              noResults: 'No passes for today.',
              noItems: 'No passes for today.'
            }"
          >
            <template #from="{ item }">
              <td class="d-flex flex-row align-items-center">
                <div v-if="item.type === 'SLP'" class="slp-student-history-box">
                  {{ item.type }}
                </div>
                <div v-else>
                  <img
                    v-if="item.tardy_badge === 'LTC' && isUsageWithinEHP"
                    width="25"
                    height="30"
                    src="@/assets/images/late-to-class.png"
                  />
                  <img
                    v-if="item.tardy_badge === 'LTS' && isUsageWithinEHP"
                    width="25"
                    height="30"
                    src="@/assets/images/late-to-school.png"
                  />
                </div>
                {{ item.from }}
              </td>
            </template>
            <template #to="{ item }">
              <td>
                {{ item.to }}
              </td>
            </template>

            <template #signaturesCount="{ item }">
              <td :class="{ 'unexpected-row': item.signatures.unexpected }">
                {{ item.signatures.count }}
              </td>
            </template>

            <template #time_out="{ item }">
              <td>
                {{
                  item.time_out
                    ? $helpers.transformDate(item.time_out, "h:mm A")
                    : ""
                }}
              </td>
            </template>
            <template #final_time_in="{ item }">
              <td>
                {{
                  item.final_time_in
                    ? $helpers.transformDate(item.final_time_in, "h:mm A")
                    : ""
                }}
              </td>
            </template>
            <template #total_time="{ item }">
              <td>
                {{ $helpers.formatDate(item.total_time, "hh:mm:ss", "m:ss") }}
              </td>
            </template>
          </DataTable>
        </div>
      </CCol>
      <CCol md="12" class="unexpected-pin-guide">
        <div class="unexpected-pin m-2">
          <span class="color-guide" />
          Highlighted background color indicates that there was at least one
          unexpected PIN on that pass.
        </div>
        <div v-if="isUsageWithinEHP" class="late-for m-2">
          <div class="late-for-class">
            <img
              width="25"
              height="30"
              src="@/assets/images/late-to-class.png"
            />
            <span class="late-for-class-text"> Late to Class </span>
          </div>
          <div class="late-for-school">
            <img
              class="ml-4 mr-3"
              width="25"
              height="30"
              src="@/assets/images/late-to-school.png"
            />
            <span class="late-for-class-text"> Late to School </span>
          </div>
          <div class="late-for-slp d-flex flex-row align-items-center">
            <div class="slp-student-history-box">SLP</div>
            <div class="ms-2">Student Late Pass</div>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>
import Loader from "@/v3components/shared/Loader/Loader.vue"
import { reactive, computed, onMounted, watch } from "vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import { useStore } from "vuex"
import helpersJS from "@/helpers/index.js"

export default {
  name: "StudentPassHistory",
  components: {
    Loader,
    DataTable
  },
  props: ["userId", "userPasses", "options", "setData"],

  setup(props) {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      passCollection: [],
      sorterValue: {
        column: null,
        asc: true
      },
      dataTableFields: [
        {
          key: "from",
          label: "From",
          sorter: true
        },
        {
          key: "to",
          label: "To",
          sorter: true
        },
        {
          key: "signaturesCount",
          label: "# of signatures",
          sorter: true
        },
        {
          key: "time_out",
          label: "Time Out",
          sorter: true
        },
        {
          key: "final_time_in",
          label: "Final Time In",
          sorter: true
        },
        {
          key: "total_time",
          label: "Total Time",
          sorter: true
        }
      ]
    })

    const isActiveTardyModule = computed(
      () => store.getters["schools/isActiveTardyModule"]
    )
    const tardyManagementOptions = computed(
      () => store.getters["schools/tardyManagementOptions"]
    )
    const isUsageWithinEHP = computed(() => {
      return isActiveTardyModule.value
        ? tardyManagementOptions.value.usage["within-ehp"]
        : false
    })
    const totalPassesTime = computed(() => {
      let total = "00:00:00"
      if (state.passCollection) {
        state.passCollection.forEach((pass) => {
          total = helpersJS.addMinutesToTime(
            total,
            pass.total_time,
            "HH:mm:ss",
            "HH:mm:ss"
          )
        })
      }
      return total
    })

    const tablePassCollection = computed(() => {
      return state.passCollection && state.passCollection.length
        ? state.passCollection.map((item) => {
            return {
              ...item,
              signaturesCount: item.signatures ? item.signatures.count : 0
            }
          })
        : state.passCollection
    })

    onMounted(() => {
      if (props.setData) {
        getUserPasses(props.userId)
      }
      if (props.options) {
        state.passCollection = props.options ? transformData(props.options) : []
      }
    })

    const getUserPasses = (userId) => {
      state.isLoading = true
      state.passCollection = []
      if (userId && typeof userId === "number") {
        store
          .dispatch("passes/getStudentPassHistory", userId)
          .then((response) => {
            state.isLoading = false
            const passes = response.data.data
            if (passes && passes.length) {
              state.passCollection = transformData(response.data.data)
            }
          })
          .catch(() => {
            state.isLoading = false
          })
      } else {
        store
          .dispatch("passes/getStudentPassHistoryAsStudent")
          .then((response) => {
            state.isLoading = false
            const passes = response.data.data
            if (passes && passes.length) {
              state.passCollection = transformData(passes)
            }
          })
          .catch(() => {
            state.isLoading = false
          })
      }
    }

    const transformData = (data) => {
      return data.map((e) => {
        const total = "00:00:00"
        return {
          from: e.from.name
            ? e.from.name
            : e.from.last_name + ", " + e.from.first_name,
          to: e.to.name ? e.to.name : e.from.last_name + ", " + e.to.first_name,
          signatures: e.signatures,
          time_out: e.approved_at,
          final_time_in: e.ended_at,
          total_time: helpersJS.addMinutesToTime(
            total,
            e.parent ? e.parent.total_time : e.total_time,
            "HH:mm:ss",
            "HH:mm:ss"
          ),
          tardy_badge: e.tardy_badge,
          type: e.type
        }
      })
    }

    watch(
      () => props.userId,
      (val) => {
        if (val) {
          getUserPasses(val)
        }
      }
    )

    watch(
      () => props.options,
      (val) => {
        if (val) {
          state.passCollection = props.options
            ? transformData(props.options)
            : []
        }
      }
    )

    return {
      state,
      isActiveTardyModule,
      isUsageWithinEHP,
      totalPassesTime,
      tablePassCollection,
      getUserPasses,
      transformData
    }
  }
}
</script>

<style lang="scss">
.student-history-table,
.unexpected-pin-guide {
  color: #000000 !important;
}
</style>
