import layout from "./layout"
import authentication from "./authentication"
import rooms from "./rooms"
import users from "./users"
import schools from "./schools"
import outOfOfficePasses from "./outOfOfficePasses"
import proxyPasses from "./proxyPasses"
import activePass from "./activePass"
import comments from "./comments"
import helpCenter from "./helpCenter"
import polarities from "./polarities"
import passes from "./passes"
import periods from "./periods"
import favorites from "./favorites"
import kiosks from "./kiosks"
import sockets from "./sockets"
import updates from "./updates"
import modules from "./modules"
import contactTracing from "./contactTracing"
import usersImport from "./usersImport"
import passLimits from "./passLimits"
import passBlocks from "./passBlocks"
import summaryReports from "./summaryReports"
import search from "./search"
import files from "./files"
import dashboardCounters from "@/store/modules/dashboardCounters.js"
import dashboardTable from "@/store/modules/dashboardTable.js"
import adultAptPass from "@/store/modules/adultAptPass.js"
import studentAptPass from "@/store/modules/studentAptPass.js"
import tardyModules from "./Tardy/index"
import visitorModules from "./Visitor/index"
import routerDependencies from "./Router/routerDependencies"
import totalPassReports from "@/store/modules/totalPassReports.js"

export const storeModules = {
  layout,
  authentication,
  rooms,
  users,
  schools,
  outOfOfficePasses,
  proxyPasses,
  polarities,
  passes,
  periods,
  activePass,
  comments,
  helpCenter,
  favorites,
  kiosks,
  sockets,
  updates,
  modules,
  contactTracing,
  usersImport,
  passLimits,
  passBlocks,
  summaryReports,
  search,
  files,
  dashboardCounters,
  dashboardTable,
  adultAptPass,
  studentAptPass,
  ...tardyModules,
  ...visitorModules,
  routerDependencies,
  totalPassReports
}
