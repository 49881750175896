<template>
  <div>
    <Label class="mt-2" required> Destination: </Label>
    <LazyLoadSelect
      v-model="state.form.to"
      :invalid-feedback="errors.to"
      :is-multiple="false"
      :selected="state.form.to"
      type="combined"
      :adult-pass-request-disabled="true"
      :is-s-l-p="true"
      placeholder="Teacher/Location"
      :force-clear-cache="true"
      @changed="(value) => onChangeEvent(value)"
    >
    </LazyLoadSelect>

    <InfoBox v-if="destinationUnavailability" class="danger mt-2 mb-2">
      {{
        destinationUnavailability.unavailable_type == "App\\Models\\Room"
          ? "This room is unavailable until"
          : "This adult is unavailable until"
      }}
      <span
        class="mx-1"
        v-html="
          $helpers.getHTMLDateTime(
            destinationUnavailability.to_date,
            'MMMM D, YYYY',
            'h:mm A'
          ) + '.'
        "
      />
      <span v-if="destinationUnavailability.comment"
        >Note: {{ destinationUnavailability.comment }}</span
      >
    </InfoBox>

    <InfoBox class="danger mt-2 mb-2" v-if="state.serverErrors.to_id">
      {{ state.serverErrors.to_id[0] }}
    </InfoBox>

    <FavoriteCarousel
      :key="'destination'"
      :selected="state.form.to"
      :is-editable="true"
      :show-unavailables="true"
      :isvalid="errors.to"
      section="destination"
      :action="
        (elem) => {
          onChangeEvent(elem)
        }
      "
    />

    <div
      v-if="
        state.form &&
        state.form.to &&
        state.form.to.value &&
        state.form.to.value.comment_type != 'Hidden'
      "
      class="mb-3"
    >
      <Label> Reason </Label>
      <InputField
        v-model="state.form.reason"
        :invalid-feedback="errors.reason"
        max-chars="250"
        :rows="5"
        class="w-full"
        type="textarea"
        :placeholder="
          state.form.to.value.comment_type == 'Optional'
            ? 'Reason for pass (optional)'
            : 'Reason for pass (required)'
        "
      />
      <InfoBox v-if="state.serverErrors.comment" class="danger mt-2"
        >{{ state.serverErrors.comment[0] }}
      </InfoBox>
    </div>

    <div class="tardy-late-pass d-flex flex-column mt-2">
      <p class="mb-1">Mark this pass as:</p>
      <TardyRadioGroup
        v-model="state.form.tardy_badge"
        :is-allowed-lates="true"
        :allowed-lates="allowedLates"
        :disabled="isTardyGroupDisabled"
      />
      <div v-if="state.isTardyRequired" class="invalid-feedback">
        This option is required
      </div>
    </div>
    <div v-if="state.serverErrors.tardy_badge" class="invalid-feedback">
      {{ state.serverErrors.tardy_badge[0] }}
    </div>

    <InfoBox
      v-if="state.serverRes"
      class="mt-3"
      :class="{ danger: Object.keys(state.serverErrors).length }"
      :title="state.serverRes.title"
      >{{ state.serverRes.message }}</InfoBox
    >

    <div class="form-group form-actions mt-2 text-center">
      <div class="d-inline" @click="submit()">
        <LoadingButton :is-loading="state.isLoading" class="mt-2" rounded solid>
          Submit
        </LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteCarousel from "@/v3components/FavoriteCarousel.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import TardyRadioGroup from "@/v3components/Forms/TardyRadioGroup.vue"
import { useStore } from "vuex"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import { reactive, computed, onMounted, watch } from "vue"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { useRouter } from "vue-router"

export default {
  name: "CreateLatePassForm",
  components: {
    LazyLoadSelect,
    FavoriteCarousel,
    Label,
    InputField,
    LoadingButton,
    TardyRadioGroup,
    InfoBox
  },
  setup() {
    const store = useStore()

    const router = useRouter()

    const state = reactive({
      isLoading: false,
      form: {
        to: [],
        reason: null,
        tardy_badge: null
      },
      serverErrors: {},
      serverRes: null,
      isTardyRequired: false
    })

    const activeSchoolModules = computed(
      () => store.getters["schools/activeSchoolModules"]
    )

    const destinationUnavailability = computed(() => {
      return state.form.to && state.form.to.value
        ? state.form.to.value.unavailability
        : null
    })

    const allowedLates = computed(() => {
      const tardyModule = activeSchoolModules.value.find(
        (module) => module.name === "Tardy Management" && module.pivot.status
      )
      let tardyModuleOptions
      if (tardyModule) {
        tardyModuleOptions = JSON.parse(tardyModule.pivot.option_json)
      }
      const lts = tardyModuleOptions["allow-students-create"].lts
      const ltc = tardyModuleOptions["allow-students-create"].ltc
      return {
        lts: lts,
        ltc: ltc
      }
    })

    const isTardyGroupDisabled = computed(() => {
      return allowedLates.value.lts && allowedLates.value.ltc ? false : true
    })

    const validationMessages = {
      required: "This field is required",
      requiredReason: "Reason for pass is required"
    }

    const validations = {
      form: {
        to: {
          available: () => {
            if (
              state.form.to &&
              state.form.to.value &&
              !state.form.to.value.unavailability
            ) {
              return true
            }
          },
          required: helpers.withMessage(validationMessages.required, required)
        },
        reason: {
          required: helpers.withMessage(
            validationMessages.requiredReason,
            requiredIf(() => {
              if (state.form.to && state.form.to.value) {
                return state.form.to.value.comment_type == "Optional"
                  ? false
                  : state.form.to.value.comment_type == "Hidden"
                    ? false
                    : true
              }
            })
          )
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })
      return errorObj
    })

    onMounted(() => {
      updateTardyBadge()
    })

    const onChangeEvent = (value) => {
      state.form.to = value

      store
        .dispatch("rooms/getUnavailability", {
          id: value.value.id,
          type: value.value.type
        })
        .then((response) => {
          if (response.data.data) {
            state.form.to.value.unavailability = response.data.data
          }
        })
    }

    const submit = () => {
      state.isTardyRequired = false
      setResponseInfoBox()
      if (isValid.value && state.form.tardy_badge !== null) {
        const pass = {
          to_id: state.form.to.value.id,
          to_type: state.form.to.value.type,
          comment: state.form.reason,
          tardy_badge: state.form.tardy_badge
        }
        state.isLoading = true
        store
          .dispatch("passes/createLatePass", pass)
          .then(() => {
            state.serverErrors = {}
            setResponseInfoBox("Success", "Successfully created.")

            setTimeout(() => {
              setResponseInfoBox()
              resetForm()
              router.push("/passes/activepass")
              state.isLoading = false
            }, 500)
          })
          .catch((err) => {
            state.isLoading = false
            const response = err?.response?.data ? err.response.data : err
            state.serverErrors = response?.errors ? response.errors : {}
            setResponseInfoBox("Error", response?.message)
          })
      } else {
        v$.value.$touch()
        if (!state.form.tardy_badge) {
          state.isTardyRequired = true
        }
      }
    }

    const resetForm = () => {
      state.form = {
        to: [],
        reason: null,
        tardy_badge: null
      }
      state.isTardyRequired = false
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const updateTardyBadge = () => {
      if (allowedLates.value.lts !== allowedLates.value.ltc) {
        if (allowedLates.value.lts) {
          state.form.tardy_badge = "LTS"
          state.isTardyRequired = false
        } else {
          state.form.tardy_badge = "LTC"
          state.isTardyRequired = false
        }
      } else {
        state.form.tardy_badge = null
      }
    }

    watch(
      () => allowedLates.value,
      () => {
        updateTardyBadge()
      }
    )

    return {
      state,
      destinationUnavailability,
      onChangeEvent,
      submit,
      allowedLates,
      isTardyGroupDisabled,
      errors
    }
  }
}
</script>

<style></style>
