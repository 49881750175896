<template>
  <div class="d-flex align-items-stretch justify-content-start flex-wrap">
    <DashboardTabListItem
      v-for="tab in availableTableTabs"
      :key="tab.value"
      :label="tab.label"
      :icon="tab.icon"
      :type="tab.type"
      :value="tab.value"
      :tab-class="tab.class"
      :count="tab.count"
      :is-active="activePassType === tab.value"
      :stats="stats"
      @on-tab-click="setActivePassType"
    />
  </div>
</template>

<script>
import DashboardTabListItem from "@/v3components/Datatables/DashboardDataTable/TabsAndFilter/DashboardTabListItem.vue"
import passConstants from "@/constants/passConstants"
import { reactive, computed, onMounted, watch } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardTabsOld",
  components: {
    DashboardTabListItem
  },
  setup() {
    const store = useStore()

    const state = reactive({
      tableTabs: [
        {
          label: "<b>My<br></b> Passes",
          icon: "flaticon-dashboard_user",
          type: "pass",
          value: "my",
          class: "pass-tabs"
        },
        {
          label: "<b>All<br></b> Passes",
          icon: "flaticon-dashboard_customer",
          type: "pass",
          value: "all",
          class: "pass-tabs"
        },
        {
          label: "Today's Appointments",
          type: "appointments",
          value: "appointments_today",
          class: "purple pass-tabs",
          count: "todayAppointmentsCount"
        },
        {
          label: "Today's Awaiting Confirmation",
          type: "appointments",
          value: "appointments_awaiting",
          class: "red pass-tabs",
          count: "awaitingTodayAppointmentsCount"
        },
        {
          label: "Future Awaiting Confirmation",
          type: "appointments",
          value: "appointments_future_awaiting",
          class: "green pass-tabs",
          count: "futureAppointmentsCount"
        }
      ]
    })

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )
    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )
    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )
    // const appTodays = computed(
    //   () => store.getters["dashboardCounters/appTodays"]
    // )
    // const appTodaysWaiting = computed(
    //   () => store.getters["dashboardCounters/appTodaysWaiting"]
    // )
    // const appFutureWaiting = computed(
    //   () => store.getters["dashboardCounters/appFutureWaiting"]
    // )
    const aptPassesCounters = computed(
      () => store.getters["dashboardCounters/aptPassesCounters"]
    )
    const globalTime = computed(() => store.getters["layout/globalTime"])
    const isUserInTransparency = computed(
      () => store.getters["authentication/isUserInTransparency"]
    )

    onMounted(() => {
      const type = store.getters["dashboardTable/getType"]
      if (
        !window.localStorage.getItem(
          passConstants.LOCAL_STORAGE_KEYS.DASHBOARD_PASS_TYPE
        ) ||
        type == "appointment" ||
        (!isActiveAppoinmentModule.value && type !== "my" && type !== "all")
      ) {
        window.localStorage.setItem(
          passConstants.LOCAL_STORAGE_KEYS.DASHBOARD_PASS_TYPE,
          "my"
        )
        store.commit("dashboardTable/UPDATE_TYPE", "my")
      }
    })

    const appointmentMetrics = computed(() => {
      return store?.getters["dashboardCounters/appointmentMetrics"] || {}
    })

    const stats = computed(() => {
      return {
        todayAppointmentsCount: appointmentMetrics?.value?.today || 0,
        awaitingTodayAppointmentsCount:
          appointmentMetrics?.value?.awaitingToday || 0,
        futureAppointmentsCount: appointmentMetrics?.value?.future || 0
      }
    })

    const availableTableTabs = computed(() => {
      const withoutType = isActiveAppoinmentModule.value ? null : "appointments"
      const withoutValues = activeSchoolHasAppoitmentPasses.value
        ? []
        : ["appointments_awaiting", "appointments_future_awaiting"]
      return state.tableTabs.filter(
        (tab) => tab.type != withoutType && !withoutValues.includes(tab.value)
      )
    })

    watch(
      () => globalTime.value,
      () => {
        store.commit(
          "dashboardCounters/UPDATE_APP_COUNTERS",
          aptPassesCounters.value
        )
      }
    )

    const setActivePassType = (type) => {
      store.commit("dashboardTable/UPDATE_TYPE", type)
      //set deafult value to "active" if user is not in transparency
      if (!isUserInTransparency.value && type == "all") {
        store.commit("dashboardTable/UPDATE_STATUS", "active")
      }

      if (isActiveAppoinmentModule.value) {
        store.dispatch("dashboardCounters/getAppointmentsCountersV2")
      }
    }

    return {
      state,
      activePassType,
      stats,
      availableTableTabs,
      setActivePassType
    }
  }
}
</script>
